<template>
  <div class="container">
    <table>
      <tr><td>
        <h2>Contact Amy</h2>
        <form @submit="checkForm" method="POST" action="/submit-contact.php">
          <div class="form-group required">
              <label for="name">Name</label>
              <input type="text" class="form-control" name="name" id="name" v-model="name" />
          </div>
          <div class="form-group required">
              <label for="email">Email</label>
              <input type="email" class="form-control" name="email" id="email" v-model="email" />
          </div>
          <div class="form-group required">
              <label for="message">Message</label>
              <textarea class="form-control" name="message" id="message" rows="15" v-model="message"></textarea>
          </div>
          <button type="submit" name="submit" id="submit-button">Submit</button>
        </form>
      </td></tr>
      <tr><td>
        <p>Your email will not be shared.<br>
        School-provided emails usually cannot receive mail from those outside school.</p>
      </td></tr>
    </table>
  </div>
</template>

<script>
export default {
    name: "Contact",
    data() {
      return {
        name: null,
        email: null,
        message: null
      }
    },
    methods: {
      checkForm: function(e) {
        if (this.name && this.email && this.message) {
          return true;
        }
        const requiredFields = document.querySelectorAll(".required");
        for (let field of requiredFields) {
          if (field.lastChild.value === "") {
            field.classList.add("error");
          } else {
            field.classList.remove("error");
          }
        }
        e.preventDefault();
      }
    }
}
</script>

<style scoped>
table, tr, td {
  border: none;
}
div {
  font-size: 1.2rem;
}
h2 {
  margin: 0;
  padding: 0;
}
form {
  padding: 2rem;
  display: flex;
  flex: 1 0 1;
  flex-direction: column;
  justify-content: flex-start;
  width: 70%;
  max-width: 650px;
  margin-left: 1rem;
}
.form-group {
  padding-bottom: 2rem;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}
label {
  font-weight: bold;
}
.radio {
  margin-top: .3rem;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
}
.radio input {
  margin-right: .3rem;
}
.radio label {
  font-weight: normal;
}
.form-control {
  height: 2rem;
  border: 1px solid #ddd;
  border-radius: 0;
  padding-left: 5px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  margin-top: 3px;
}
.form-control:focus {
  outline: none;
}
textarea {
  resize: none;
  width: 100%;
  min-height: 12rem;
  padding-top: 5px;
}
#submit-button {
  border: 2px solid #407137;
  border-radius: 8px;
  background: #407137;
  color: white;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  width: 50%;
  margin-top: 1rem;
  height: 50px;
}
#submit-button:hover {
  background: white;
  color: #407137;
  cursor: pointer;
}
#submit-button:focus {
  outline: none;
}
.form-group.error .form-control {
  outline: 1px dashed red;
}
.form-group.error::after {
  content: "This field is required.";
  color: red;
  font-size: .8rem;
  margin-top: .2rem;
}
</style>